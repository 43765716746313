<template>
  <div class="flex w-full h-screen flex-row login-section">
    <div v-show="showVideo" class="video-box">
      <div v-click-away="handleVideoFocusOut" class="video-main">
        <div id="player">
          <iframe
            src="https://youtu.be/vQxmVqp8X30"
            loading="lazy"
            allowfullscreen
            allowtransparency
            allow="autoplay"
          ></iframe>
        </div>
      </div>
    </div>

    <div class="flex flex-col shop_setup lg:flex-row h-full">
      <div class="first-section grid flex-1">
        <div class="text-box h-full">
          <h1>{{ $t("connect.title") }}</h1>
          <p>
            {{ $t("connect.description") }}
          </p>
        </div>
      </div>
      <div class="section2 justify-between lg:overflow-y-auto">
        <div class="inner-section">
          <div
            class="
              navigation
              justify-between
              gap-y-3
              lg:gap-y-0
              flex flex-col
              lg:flex-row
            "
          >
            <div v-on:click="goStep1" class="item-navigation">
              <div class="container-number">
                <div class="number">1</div>
              </div>
              <div class="name-navigation">
                {{ $t("synchronization.choose_store") }}
              </div>
            </div>
            <div class="line-navigation"></div>
            <div class="item-navigation">
              <div class="container-number">
                <div class="number number-completed">2</div>
              </div>
              <div class="name-navigation">
                {{ $t("synchronization.configure") }}
              </div>
            </div>
            <div class="line-navigation"></div>
            <div class="item-navigation">
              <div class="container-number"><div class="number">3</div></div>
              <div class="name-navigation">
                {{ $t("synchronization.sync") }}
              </div>
            </div>
          </div>
          <div class="mid-content my-8">
            <div
              :class="{
                'box-error': pluginErrorMessage,
                'box-completed': isPluginActive
              }"
              class="ecommerce-box w-full max-w-3xl flex flex-col"
            >
              <h1 class="title-box">{{ $t("connect.plugin_title") }}</h1>
              <p class="description-box mt-2 mb-2">
                {{ $t("connect.plugin_description") }}
              </p>
              <p
                v-if="pluginErrorMessage"
                class="text-left text-xsm text-error font-bold"
              >
                {{ pluginErrorMessage }}
              </p>
              <div class="line-button mt-8" v-on:click="toogleVideo">
                <play-button /><span>{{ $t("home.watch_video") }}</span>
              </div>
            </div>
            <div
              :class="{
                'box-error': storePermErrorMessage,
                'box-completed': isStorePermSet
              }"
              class="ecommerce-box w-full max-w-3xl flex flex-col"
            >
              <h1 class="title-box">{{ $t("connect.access_title") }}</h1>
              <p class="description-box mt-2 mb-2">
                {{ $t("connect.access_description") }}
              </p>
              <p
                v-if="storePermErrorMessage"
                class="text-left text-xsm text-error font-bold"
              >
                {{ storePermErrorMessage }}
              </p>
              <div
                v-on:click="openPermissionTab"
                class="button-connect flex flex-row items-center justify-center"
              >
                <spinner v-if="showLoading" />
                {{ $t("connect.access_button") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, watch, ref, onMounted } from "vue"
import { useStore } from "@/store"
import { useRouter } from "vue-router"
import Spinner from "@/components/Icons/Spinner.vue"
import { useI18n } from 'vue-i18n'
import PlayButton from "@/components/Icons/PlayButton.vue"
import Plyr from "plyr"

export default defineComponent({
  components: {
    Spinner,
    PlayButton
  },
  setup() {
    const pluginErrorMessage = ref(null)
    const storePermErrorMessage = ref(null)
    const wantToOpenPermissionTab = ref(false)
    const showLoading = ref(false)
    const { t } = useI18n({ useScope: 'global' })

    const player = ref(null)
    const showVideo = ref(false)
    const videoFullyOpen = ref(false)

    const store = useStore()
    const router = useRouter()
    const shop = computed(() => store.getters["auth/shop"])
    const clientID = computed(() => store.getters["auth/getClientId"])
    const isSecondStepDone = computed(
      () => store.getters["storeSetup/isSecondStepDone"]
    )
    const isPluginActive = computed(
      () => store.getters["storeSetup/isPluginActive"]
    )
    const isStorePermSet = computed(
      () => store.getters["storeSetup/isStorePermSet"]
    )
    const syncStatus = computed(() => store.getters["storeSetup/syncStatus"])

    /*
     *   Watch methods for when a request is made to check if our plugin is active,
     *   when we receive a message from channels with the confirmation that we have shop permission (credencials)
     *   and when we can continue with integration
     */
    watch(isPluginActive, (newValue, oldValue) => {
      showLoading.value = false;
      if (!newValue) {
        pluginErrorMessage.value = t("connect.error_plugin");
      } else if (wantToOpenPermissionTab.value) {
        openPermissionTab();
      }
    });
    watch(isStorePermSet, (newValue, oldValue) => {
      if (!newValue) {
        storePermErrorMessage.value = t("connect.error_store_perms");
      }
    });

    watch(syncStatus, val => {
      if (syncStatus.value) {
        router.push("/shop/sync");
      } else {
        storePermErrorMessage.value = t("connect.error_credentials");
      }
    });

    watch([isSecondStepDone, shop], (newValues, oldValues) => {
      console.error(newValues);
      if (newValues[0] && newValues[1]) {
        if (newValues[1].id) {
          store.dispatch(
            "storeSetup/startSync",
            `https://${newValues[1].id}.napps-solutions.com/sync`
          );
        }
      }
    });

    /*
     *   Methods for buttons, open plugin page to install plugin on their shop,
     *   Open permission for woocommerce to access their shop
     *   Next step button to continue with integration
     */
    const goStep1 = async () => {
      store.dispatch("storeSetup/setShopType", null)
      router.push("/shop/choose")
    }

    const toogleVideo = () => {
      showVideo.value = !showVideo.value

      if (!showVideo.value) {
        player.value.pause()
      } else {
        player.value.play()
        setTimeout(() => {
          videoFullyOpen.value = true
        }, 1000)
      }
    }

    const handleVideoFocusOut = () => {
      if (showVideo.value && videoFullyOpen.value) {
        toogleVideo()
        videoFullyOpen.value = false
      }

    }

    const openPermissionTab = async () => {
      if (!shop.value || isStorePermSet.value) {
        return;
      }

      if (!isPluginActive.value) {
        wantToOpenPermissionTab.value = true;
        showLoading.value = true;
        store.dispatch(
          "storeSetup/checkPluginStatus",
          `${shop.value.url}/wp-json/napps/v1/status`
        );
        return;
      }

      wantToOpenPermissionTab.value = false;

      const url = `${shop.value.url}/wc-auth/v1/authorize?app_name=NAPPS&scope=read_write&user_id=${clientID.value}&return_url=${encodeURIComponent(window.location.href)}&callback_url=${encodeURIComponent(window.nappmaster + '/shop/woocommerce')}`;
      window.location.href = url;
    };

    // const onNextStep = async () => {
    //   if (!shop.value) {
    //     return;
    //   }

    //   if (!isPluginActive.value) {
    //     showLoading.value = true;
    //     store.dispatch(
    //       "storeSetup/checkPluginStatus",
    //       `${shop.value.url}/wp-json/napps/v1/status`
    //     );
    //   }
    //   if (!isStorePermSet.value) {
    //     store.dispatch("storeSetup/setShopPerm", false);
    //   }

    //   if (isSecondStepDone.value) {
    //     store.dispatch(
    //       "storeSetup/startSync",
    //       `https://${shop.value.id}.napps-solutions.com/sync`
    //     );
    //   }
    // };

    onMounted(() => {

      player.value = new Plyr("#player");

      /*
       *   Check if we have a query with complete in our router page
       *   if so, check the result and set it in the store
       */
      // eslint-disable-next-line no-prototype-builtins
      const isFromWoocommerce = router.currentRoute.value.query.hasOwnProperty(
        "success"
      );
      if (isFromWoocommerce) {
        store.dispatch("storeSetup/setShopType", "wordpress");

        const havePermission = router.currentRoute.value.query.success === "1";
        store.dispatch("storeSetup/setPluginActive", havePermission);
        store.dispatch("storeSetup/setShopPerm", havePermission);
      } else if (!shop.value || !shop.value.type) {
        //router.push("/shop/choose");
      }
    })

    return {
      pluginErrorMessage,
      isPluginActive,
      storePermErrorMessage,
      isStorePermSet,
      openPermissionTab,
      // onNextStep,
      goStep1,
      showLoading,
      toogleVideo,
      showVideo,
      handleVideoFocusOut
    }
  }
})
</script>

<style scoped>

.line-button {
  display: flex;
  flex-direction: row;
  color: #263238;
  align-items: center;
  text-transform: uppercase;
  font-family: GothamBook, "sans-serif";
  cursor: pointer;
}

.line-button > svg {
  margin-right: 10px;
  width: 50px;
  height: auto;
}

.video-box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.377);
  z-index: 200;
}

.video-main {
  width: 70%;
  height: auto;
}

.name-navigation {
  font-family: GothamMedium;
}
.button-next {
  font-family: GothamMedium;
}
.button-connect {
  font-family: GothamMedium;
}
.number {
  color: #284452;
}
.number-completed {
  color: white;
}
.text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0px 50px;
}
.text-box > h1 {
  color: white;
  font-family: GothamMedium;
  font-size: 40px;
}
.text-box > p {
  color: white;
  font-family: GothamBook;
  margin-top: 15%;
  font-size: 22px;
}
.first-section {
  background-color: #263238;
}
.main-title {
  color: #263238;
  font-family: GothamMedium;
  font-size: 40px;
}
.row-boxes {
  align-items: center;
  gap: 10%;
  margin-top: 50px;
}
.ecommerce-box {
  width: 50%;
}
.inner-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 50px 0px;
  min-width: 75%;
  max-width: 75%;
  margin: auto;
}
.ecommerce-box {
  padding: 30px 30px;
  justify-content: center;
  min-width: 100%;
}
.ecommerce-desc {
  font-family: GothamBook;
  font-size: 20px;
  color: #5a5a5a;
}

.mid-content {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1040px) {
  .first-section {
    display: none;
  }
  .shop_setup {
    width: 100%;
  }
  .shop_setup .navigation {
    flex-direction: row;
  }
  .ecommerce-box {
    width: 100%;
  }
  .bottom-navigation {
    align-self: center;
  }
}

@media screen and (max-width: 740px) {
    .shop_setup .navigation {
    flex-direction: column;
  }
  .item-navigation {
    min-width: 100%;
    display: flex;
  }
  .line-navigation {
    display: none;
  }
}
.button-connect {
  background-color: #284452;
  font-family: GothamMedium;
}

.title-box {
  color: #284452;
  font-family: GothamMedium;
  font-weight: 500;
}
.description-box {
  color: #696969;
  font-family: GothamBook;
}
.text-error {
  color: #f46666;
  font-family: GothamMedium;
}
.name-navigation {
  font-family: GothamMedium;
}
.step-link {
  color: #626262;
  font-family: GothamMedium;
}
.button-next {
  font-family: GothamMedium;
}
.number {
  color: #284452;
}
.number-completed {
  color: white;
}
</style>

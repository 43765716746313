<template>
  <div class="flex w-full h-screen flex-row login-section">
    <div class="flex flex-col shop_setup lg:flex-row h-full">
      <div class="first-section grid flex-1">
        <div class="text-box h-full">
          <h1>{{ $t("chooseStore.title") }}</h1>
          <p>
            {{ $t("chooseStore.description") }}
          </p>
        </div>
      </div>
      <div class="section2 justify-between lg:overflow-y-auto">
        <div class="inner-section justify-between">
          <div
            class="
              navigation
              justify-between
              gap-y-3
              lg:gap-y-0
              flex flex-col
              lg:flex-row
            "
          >
            <div class="item-navigation">
              <div class="container-number">
                <div class="number number-completed">1</div>
              </div>
              <div class="name-navigation">
                {{ $t("synchronization.choose_store") }}
              </div>
            </div>
            <div class="line-navigation"></div>
            <div class="item-navigation">
              <div class="container-number"><div class="number">2</div></div>
              <div class="name-navigation">
                {{ $t("synchronization.configure") }}
              </div>
            </div>
            <div class="line-navigation"></div>
            <div class="item-navigation">
              <div class="container-number"><div class="number">3</div></div>
              <div class="name-navigation">
                {{ $t("synchronization.sync") }}
              </div>
            </div>
          </div>
          <div>
            <h2 class="main-title">{{ $t("chooseStore.title") }}</h2>
            <div class="flex flex-col mt-12 max-w-xl">
              <div
                v-on:click="onShopSelected('wordpress')"
                :class="{ 'box-completed': shopSelected == 'wordpress' }"
                class="
                  h-40
                  justify-between
                  ecommerce-box
                  flex flex-row
                  items-center
                  cursor-pointer
                "
              >
                <img
                  alt="Woocommerce logo"
                  class=""
                  style="object-fit: contain; max-width: 65%; max-height: 100px"
                  src="https://nappscdn.s3.eu-west-3.amazonaws.com/woocommerce.png"
                />
                <div
                  :class="
                    shopSelected == 'wordpress'
                      ? 'bg-primary'
                      : 'border border-gray-500'
                  "
                  class="
                    rounded-full
                    h-10
                    w-10
                    flex
                    items-center
                    justify-center
                  "
                >
                  <svg
                    width="14"
                    height="12"
                    style="
                      object-fit: contain;
                      max-width: 65%;
                      max-height: 100px;
                    "
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 6.44663L4.79421 11.25L14 2.0533L12.6784 0.749939L4.79421 8.62493L1.30334 5.13408L0 6.44663Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <div
                v-on:click="onShopSelected('shopify')"
                :class="{
                  'box-error': storeError,
                  'box-completed': shopSelected == 'shopify',
                }"
                class="
                  flex flex-col
                  mt-8
                  h-40
                  ecommerce-box
                  justify-evenly
                  cursor-pointer
                "
              >
                <div class="justify-between flex flex-row items-center">
                  <img
                    alt="Shopify Logo"
                    class=""
                    style="
                      object-fit: contain;
                      max-width: 50%;
                      max-height: 100px;
                    "
                    src="https://d3dx13u2x4z410.cloudfront.net/shopify_logo_black.png"
                  />
                  <div
                    :class="
                      shopSelected == 'shopify' || storeError
                        ? 'bg-primary'
                        : 'border border-gray-500'
                    "
                    class="
                      rounded-full
                      h-10
                      w-10
                      flex
                      items-center
                      justify-center
                    "
                  >
                    <svg
                      width="14"
                      height="12"
                      style="
                        object-fit: contain;
                        max-width: 65%;
                        max-height: 100px;
                      "
                      viewBox="0 0 14 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 6.44663L4.79421 11.25L14 2.0533L12.6784 0.749939L4.79421 8.62493L1.30334 5.13408L0 6.44663Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <p
                  v-if="storeError"
                  class="text-left text-xsm text-red-500 font-bold"
                >
                  {{ storeError }}
                </p>
              </div>
            </div>
          </div>
          <div class="bottom-navigation self-end">
            <div class="button-next" v-on:click="onNextStep">
              {{ $t("synchronization.next") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { watch, defineComponent, computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
export default defineComponent({
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const shopSelected = ref("");
    const storeError = ref(null);

    const isFirstStepDone = computed(
      () => store.getters["storeSetup/isFirstStepDone"]
    );
    watch(isFirstStepDone, (newValue) => {
      if (newValue) {
        router.push("/shop/configure");
      }
    });
    const onShopSelected = async (shop) => {
      store.dispatch("auth/updateShopType", shop);
      if (shop === "shopify") {
        shopSelected.value = "";
        storeError.value =
          "We will be on Shopify soon, you will be notified by the time!";
        return;
      }

      storeError.value = null;
      shopSelected.value = shop;
    };

    const onNextStep = async () => {
      if (!shopSelected.value) {
        return;
      }

      store.dispatch("storeSetup/setShopType", shopSelected.value);
    };
    return {
      onShopSelected,
      onNextStep,
      shopSelected,
      storeError,
      isFirstStepDone,
    };
  },
});
</script>

<style scoped>
.name-navigation {
  font-family: GothamMedium;
}
.button-next {
  font-family: GothamMedium;
}
.button-connect {
  font-family: GothamMedium;
}
.number {
  color: #284452;
}
.number-completed {
  color: white;
}
.text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0px 50px;
}
.text-box > h1 {
  color: white;
  font-family: GothamMedium;
  font-size: 40px;
}
.text-box > p {
  color: white;
  font-family: GothamBook;
  margin-top: 15%;
  font-size: 22px;
}
.first-section {
  background-color: #263238;
}
.main-title {
  color: #263238;
  font-family: GothamMedium;
  font-size: 40px;
}

.inner-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0px;
  min-width: 75%;
  max-width: 75%;
  margin: auto;
}

.ecommerce-desc {
  font-family: GothamBook;
  font-size: 20px;
  color: #5a5a5a;
}

@media screen and (max-width: 1040px) {
  .first-section {
    display: none;
  }
  .shop_setup {
    width: 100%;
  }
  .shop_setup .navigation {
    flex-direction: row;
  }
  .ecommerce-box {
    width: 100%;
  }
  .bottom-navigation {
    align-self: center;
  }
}

@media screen and (max-width: 740px) {
  .shop_setup .navigation {
    flex-direction: column;
  }
  .item-navigation {
    min-width: 100%;
    display: flex;
  }
  .line-navigation {
    display: none;
  }
}
</style>

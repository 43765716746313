<template>
  <div class="flex w-full h-screen flex-row login-section">
    <div class="flex flex-col shop_setup lg:flex-row h-full">
      <div class="first-section grid flex-1">
        <div class="text-box h-full">
          <h1>{{$t("synchronize.title")}}</h1>
          <p>
          {{$t("synchronize.description")}}
          </p>
        </div>
      </div>
      <div class="section2 relative justify-between lg:overflow-y-auto">
        <div class="inner-section justify-between">
          <div
            class="
              navigation
              justify-between
              gap-y-3
              lg:gap-y-0
              flex flex-col
              lg:flex-row
            "
          >
            <div class="item-navigation">
              <div class="container-number">
                <div class="number">1</div>
              </div>
              <div class="name-navigation">{{$t("synchronization.choose_store")}}</div>
            </div>
            <div class="line-navigation"></div>
            <div class="item-navigation">
              <div class="container-number"><div class="number">2</div></div>
              <div class="name-navigation">{{$t("synchronization.configure")}}</div>
            </div>
            <div class="line-navigation"></div>
            <div class="item-navigation">
              <div class="container-number"><div class="number number-completed">3</div></div>
              <div class="name-navigation">{{$t("synchronization.sync")}}</div>
            </div>
          </div>
          <div class="mid-content">
            <div
              class="content-box gap-y-8 items-center justify-center flex-grow"
            >
              <div class="half-circle-spinner">
                <div class="circle circle-1"></div>
                <div class="circle circle-2"></div>
              </div>
              <h1 class="title-box" v-if="lastSyncMessage">
                {{ lastSyncMessage }} Done.
              </h1>
              <h1 class="title-box" v-else>Shop sync is starting...</h1>
            </div>
          </div>
          <div class="bottom-navigation">
            
          </div>
        </div>
        <open-dashboard v-show="showPopup" @close="onClose" />
      </div>
      
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, watch, ref } from 'vue'
import { useStore } from '@/store'
import OpenDashboard from './OpenDashboard.vue'

export default defineComponent({
  components: {
    OpenDashboard,
  },
  setup() {
    const store = useStore()
    const showPopup = ref(undefined)

    const shop = computed(() => store.getters['auth/shop'])
    const isThirdStepDone = computed(
      () => store.getters['storeSetup/isThirdStepDone']
    )
    const lastSyncMessage = computed(
      () => store.getters['storeSetup/lastSyncMessage']
    )
    const isCollectionsDone = computed(
      () => store.getters['storeSetup/isCollectionsDone']
    )
    
    watch(isCollectionsDone, (newValue, oldValue) => {
      if(newValue == true && showPopup.value == undefined) {
        showPopup.value = true
      }
    })

    const onClose = () => {
      showPopup.value = false
    }
    
    watch([isThirdStepDone, shop], (newValue, oldValue) => {
      if (isThirdStepDone.value && shop.value) {
        store.dispatch('auth/openDashboard', {
          href: `https://${shop.value.id}.napps-solutions.com`,
          first: true
        })
      }
    })

    const openDashboard = () => {
      store.dispatch('auth/openDashboard', {
        href: `https://${shop.value.id}.napps-solutions.com`,
        first: true
      })
    }

    return { shop, lastSyncMessage, openDashboard, onClose, showPopup }
  }
})
</script>

<style scoped>
.name-navigation {
  font-family: GothamMedium;
}
.button-next {
  font-family: GothamMedium;
}
.button-connect {
  font-family: GothamMedium;
}
.number {
  color: #284452;
}
.number-completed {
  color: white;
}
.inner-section {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 0px;
  min-width: 75%;
  max-width: 75%;
  margin: auto;
}
.text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0px 50px;
}
.text-box > h1 {
  color: white;
  font-family: GothamMedium;
  font-size: 40px;
}
.text-box > p {
  color: white;
  font-family: GothamBook;
  margin-top: 15%;
  font-size: 22px;
}
.first-section {
  background-color: #263238;
}

.name-navigation {
  font-family: GothamMedium;
}

.section1 {
  background-color: #284452;
}

.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: calc(60px / 10) solid transparent;
}

.half-circle-spinner .circle.circle-1 {
  border-top-color: #263238;
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  border-bottom-color: #263238;
  animation: half-circle-spinner-animation 1s infinite alternate;
}

.title-box {
  color: #284452;
  font-family: GothamMedium;
  font-weight: 600;
}

.button-connect {
  background-color: #284452;
  font-family: GothamMedium;
}

.title-box {
  color: #284452;
  font-family: GothamMedium;
  font-weight: 500;
}
.description-box {
  color: #696969;
  font-family: GothamBook;
}
.text-error {
  color: #f46666;
  font-family: GothamMedium;
}
.name-navigation {
  font-family: GothamMedium;
}
.step-link {
  color: #626262;
  font-family: GothamMedium;
}
.button-next {
  font-family: GothamMedium;
}
.number {
  color: #284452;
}
.number-completed {
  color: white;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

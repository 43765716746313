<template>
  <transition name="fade">
    <div
      class="flex flex-col absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform bg-white shadow-xl p-14 rounded-lg items-center text-center w-full lg:max-w-xl"
    >
      <p class="font-gothammedium text-lg text-primary">
        {{ $t("synchronization.open_dashboard_title") }}
      </p>
      <p class="text-sm font-gothambook mt-4 text-black800">
        {{ $t("synchronization.open_dashboard_desc") }}
      </p>
      <div class="flex flex-row mt-6 font-gothammedium">
        <p
          v-on:click="openDashboard"
          class="mr-4 cursor-pointer bg-primary text-white rounded px-10 py-1"
        >
          {{ $t("synchronization.open_dashboard_yes") }}
        </p>
        <p
          v-on:click="close"
          class="font-gothammedium cursor-pointer text-primary leading-8"
        >
          {{ $t("synchronization.open_dashboard_no") }}
        </p>
      </div>
    </div>
  </transition>
</template>
<script>
import { defineComponent, computed, onMounted, ref } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  setup(props, context) {
    const store = useStore();
    const shop = computed(() => store.getters["auth/shop"]);

    const close = () => {
      context.emit("close");
    };
    const openDashboard = () => {
      store.dispatch("auth/openDashboard", {
        href: `https://${shop.value.id}.napps-solutions.com`,
        first: true
      });
    };

    onMounted(() => {});

    return { shop, openDashboard, close };
  }
});
</script>

<style scoped></style>
